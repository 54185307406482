import { mediaNews } from '@/data/newsroom.js';
import { sortMediaNews } from '@/helpers/Utils.js';
import EvoMarkdown from '@/components/Md.vue';

export const newsroomMixin = {
  data() {
    return {
      isPolicy: false
    };
  },
  watch: {
    '$route.name': {
      handler: 'routeNameChange',
      immediate: true
    }
  },
  methods: {
    routeNameChange(name) {
      this.isPolicy = name === 'NewsroomDetail';
    }
  }
};
export const newsroomHomeMixin = {
  data() {
    return {
      loading: false,
      items: mediaNews.items.sort(sortMediaNews)
    };
  },
  computed: {
    bigItem() {
      return this.items[0];
    },
    firstRow() {
      return this.items.slice(1, 3);
    },
    secondRow() {
      return this.items.slice(3);
    }
  },
  methods: {
    load() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 3000);
    },
    goTo(link) {
      this.$router.push(link).catch(() => {});
    }
  }
};
export const newsroomDetailMixin = {
  components: { EvoMarkdown },
  computed: {
    id() {
      return +this.$route.params.id;
    },
    items() {
      const getIndex = item => {
        return item.link.slice(item.link.length - 1);
      };
      return mediaNews.items.filter(f => getIndex(f) != +this.id).sort(sortMediaNews);
    },
    md() {
      return require(`@/views/newsroom/markdown/news${this.id}.md`);
    },
    breadcrumbs() {
      return [
        {
          text: this.$t('news.title'),
          disabled: false,
          href: '/newsroom'
        }
        // {
        //   // text: this.id,
        //   text: this.$t("legal.privacy_policy"),
        //   disabled: true,
        //   href: "",
        // },
      ];
    }
  }
};
