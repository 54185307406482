<template>
  <div class="evo-news-home-wrapper evo-mobile mx-auto">
    <v-row class="evo-policy-items">
      <v-col cols="12" v-for="(item, index) in [bigItem, ...firstRow, ...secondRow]" :key="index">
        <v-card class="evo-item-wrapper">
          <div class="evo-item">
            <v-card class="evo-image-warpper">
              <v-img :height="index === 0 ? 370 : 202" :src="item.smallImg"></v-img>
            </v-card>

            <div class="evo-policy-info">
              <div class="evo-item-date evo-text-31 evo-opensans-regular">
                {{ item.date }}
              </div>
              <router-link :to="item.link" class="router-link">
                <div class="evo-item-title evo-text-35">
                  {{ $t(item.title) }}
                </div>
              </router-link>
              <div class="evo-read-more text-right">
                <router-link :to="item.link" class="router-link">
                  <span class="evo-link evo-text-29 evo-opensans-regular">
                    {{ $t(item.linkText) }}
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- load more btn // TODO: 暂时不需要这个功能 -->
    <div v-if="false" class="evo-load-btn-wrapper d-flex justify-center">
      <v-btn rounded x-large class="text-none evo-load-btn evo-opensans-regular" :loading="loading" :disabled="loading" @click="load()">
        {{ $t('load_more') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { newsroomHomeMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoMNewsroomHomeSection',
  mixins: [newsroomHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-home-wrapper {
  position: relative;
  padding-top: 40px;

  .evo-policy-items {
    margin-bottom: 110px;
    .evo-item-wrapper {
      background: #ffffff;
      box-shadow: 40px 40px 118px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

    .evo-item {
      padding: 30px;
      .evo-image-warpper {
        position: relative;
        overflow: hidden;
        box-shadow: 20px 20px 68px 0px rgba(0, 0, 0, 0.3);
        margin-bottom: 42px;
      }
      .evo-policy-info {
        padding: 0 30px;
        .evo-item-date {
          color: #595f6f;
          margin-bottom: 16px;
        }

        .evo-item-title {
          color: #1a47b0;
          line-height: 47px;
        }
      }

      .evo-read-more {
        margin-top: 16px;
        .evo-link {
          color: #595f6f;
        }
      }
    }
  }

  .evo-load-btn-wrapper {
    .evo-load-btn {
      @include lightblue_btn;
      width: 279px;
      height: 91px;
      font-size: 29px;
    }
  }
}
</style>
