<template>
  <div class="evo-newsroom-home-wrapper">
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMNewsroomHomeSection />
    </template>
    <template v-else>
      <EvoNewsroomHomeSection />
    </template>
  </div>
</template>
<script>
import EvoNewsroomHomeSection from '@/components/newsroom_sections/Home.vue';
import EvoMNewsroomHomeSection from '@/mobile/components/newsroom_sections/MHome.vue';
export default {
  name: 'EvoNewsroomHomeView',
  components: {
    EvoNewsroomHomeSection,
    EvoMNewsroomHomeSection
  }
};
</script>
<style lang="scss" scoped>
.evo-newsroom-home-wrapper {
  position: relative;
  background: $header_bg_color;
}
</style>