<template>
  <div class="evo-news-home-wrapper">
    <!-- first row -->
    <v-row class="evo-policy-items">
      <v-col cols="12" sm="12" md="8">
        <v-card class="evo-item-wrapper-lagrge" data-aos="fade-up">
          <div class="evo-item pa-3">
            <v-card class="evo-image-warpper mb-6">
              <v-img height="369" :src="bigItem.smallImg"></v-img>
            </v-card>

            <div class="evo-policy-info px-3">
              <div class="evo-item-date evo-text-9 evo-opensans-regular mb-2">
                {{ bigItem.date }}
              </div>
              <router-link :to="bigItem.link" class="router-link">
                <div class="evo-item-title evo-text-26">
                  {{ $t(bigItem.title) }}
                </div>
              </router-link>
            </div>

            <div class="evo-read-more">
              <router-link :to="bigItem.link" class="router-link">
                <span class="evo-link evo-text-9 evo-opensans-regular">
                  {{ $t(bigItem.linkText) }}
                </span>
              </router-link>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-row class="evo-policy-items">
          <v-col cols="12" sm="12" md="12" v-for="(item, index) in firstRow" :key="index">
            <v-card class="evo-item-wrapper" data-aos="fade-up">
              <div class="evo-item pa-3">
                <v-card class="evo-image-warpper mb-4">
                  <v-img height="165" :src="item.smallImg"></v-img>
                </v-card>

                <div class="evo-policy-info px-3">
                  <div class="evo-item-date evo-text-9 evo-opensans-regular mb-2">
                    {{ item.date }}
                  </div>
                  <router-link :to="item.link" class="router-link">
                    <div class="evo-item-title evo-text-10">
                      {{ $t(item.title) }}
                    </div>
                  </router-link>
                </div>

                <div class="evo-read-more">
                  <router-link :to="item.link" class="router-link">
                    <span class="evo-link evo-text-9 evo-opensans-regular">
                      {{ $t(item.linkText) }}
                    </span>
                  </router-link>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- second row -->
    <v-row class="evo-policy-items mb-15">
      <v-col cols="12" sm="12" md="4" v-for="(item, index) in secondRow" :key="index">
        <v-card class="evo-item-wrapper">
          <div class="evo-item pa-3">
            <v-card class="evo-image-warpper mb-4">
              <v-img height="165" :src="item.smallImg"></v-img>
            </v-card>

            <div class="evo-policy-info px-3">
              <div class="evo-item-date evo-text-9 evo-opensans-regular mb-2">
                {{ item.date }}
              </div>
              <router-link :to="item.link" class="router-link">
                <div class="evo-item-title evo-text-10">
                  {{ $t(item.title) }}
                </div>
              </router-link>
            </div>

            <div class="evo-read-more">
              <router-link :to="item.link" class="router-link">
                <span class="evo-link evo-text-9 evo-opensans-regular">
                  {{ $t(item.linkText) }}
                </span>
              </router-link>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- load more btn // TODO: 暂时不需要这个功能 -->
    <div v-if="false" class="evo-load-btn-wrapper d-flex justify-center">
      <v-btn rounded x-large class="text-none evo-load-btn" :loading="loading" :disabled="loading" @click="load()">
        {{ $t('load_more') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { newsroomHomeMixin } from '@/mixins/newsroom.js';
export default {
  name: 'EvoNewsroomHomeSection',
  mixins: [newsroomHomeMixin]
};
</script>
<style lang="scss" scoped>
.evo-news-home-wrapper {
  position: relative;
  margin-top: 38px;
  margin-left: -54px;
  margin-right: -54px;

  .evo-policy-items {
    .evo-item-wrapper,
    .evo-item-wrapper-lagrge {
      background: #ffffff;
      box-shadow: 16px 16px 47px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    .evo-item {
      position: relative;

      .evo-image-warpper {
        position: relative;
        overflow: hidden;
        box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.3);
        .evo-image-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
        }
      }
      .evo-policy-info {
        .evo-item-date {
          color: #595f6f;
          line-height: 12px;
        }

        .evo-item-title {
          color: #1a47b0;
          height: 80px;
          line-height: 19px;
        }
      }

      .evo-read-more {
        position: absolute;
        bottom: 12px;
        right: 12px;

        .evo-link {
          color: #595f6f;
        }
      }
    }

    .evo-item-wrapper-lagrge {
      height: 100%;
      .evo-item {
        height: 100%;
        .evo-item-title {
          height: 160px;
          line-height: 38px;
        }
      }
    }
  }

  .evo-load-btn-wrapper {
    margin-bottom: 130px;
  }

  .evo-load-btn {
    @include lightblue_btn;
    width: 130px;
  }
}
</style>
